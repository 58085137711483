import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'button-cell',
    template: `
        <div [ngSwitch]="status">
            <div *ngSwitchCase="'VERIFIED'" class="badge green">Verified</div>
            <div *ngSwitchCase="'UPLOADED'" class="badge gray">Uploaded</div>
            <div *ngSwitchDefault class="badge dark">{{ status }}</div>
        </div>
    `,
    styles: [
        `
            .badge {
                align-items: center;
                border-radius: 4px;
                display: inline-flex;
                font-size: 12px;
                font-weight: bold;
                height: 20px;
                padding: 0 8px;
                position: relative;
            }
            .green {
                color: white;
                background-color: #1E8A44;
            }
            .gray {
                color: #171C1E;
                background-color: #F1F1F6;
            }
            .dark {
                color: white;
                background-color: #6a6e79;
            }
        `,
    ],
})
export class documentStatusCellEditor implements ICellRendererAngularComp {
    public params!: any;
    status = '';

    agInit(params: any): void {
        this.params = params;
        this.status = this.params.data.status;
    }

    public acceptRequest() {
        this.params.onClickAccept(this.params.data.email);
    }

    refresh(): boolean {
        return false;
    }
}
