import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  Decimal: any;
};

export type AddOrganizationProjectInput = {
  cropzoneIds: Array<Scalars['Int']>;
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type BinaryFilter = {
  expr?: InputMaybe<CustomDataFilter>;
  exprGrp?: InputMaybe<Array<BinaryFilter>>;
  grpOp?: InputMaybe<BinaryFilterOperation>;
};

export enum BinaryFilterOperation {
  And = 'AND',
  AndNot = 'AND_NOT',
  Or = 'OR',
  OrNot = 'OR_NOT'
}

export type Boundary = {
  __typename?: 'Boundary';
  maxX: Scalars['Float'];
  maxY: Scalars['Float'];
  minX: Scalars['Float'];
  minY: Scalars['Float'];
};

export type CropZoneDetail = {
  __typename?: 'CropZoneDetail';
  boundary: Boundary;
  cropYear: Scalars['Int'];
  geometryObject?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  ownership?: Maybe<Scalars['String']>;
};

export enum CropZoneResult4RLevel {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE'
}

export type CropZoneTableResult = {
  __typename?: 'CropZoneTableResult';
  acres: Scalars['Float'];
  credits: Scalars['Decimal'];
  cropName?: Maybe<Scalars['String']>;
  cropYear: Scalars['Int'];
  ecoDistrict?: Maybe<Scalars['String']>;
  emissions: Scalars['Float'];
  id: Scalars['Int'];
  irrigated: Scalars['String'];
  landLocation: Scalars['String'];
  lastUpdated?: Maybe<Scalars['DateTimeOffset']>;
  latitude: Scalars['Float'];
  level?: Maybe<CropZoneResult4RLevel>;
  longitude: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  ownership: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  tillage: Scalars['String'];
  totalNitrogenKg: Scalars['Float'];
  totalYieldKg: Scalars['Float'];
};

export type CustomDataFilter = {
  column: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type NerpMutation = {
  __typename?: 'NerpMutation';
  addCropzoneProject: Scalars['ID'];
  updateCropZoneLevel: Scalars['Int'];
};


export type NerpMutationAddCropzoneProjectArgs = {
  input: AddOrganizationProjectInput;
};


export type NerpMutationUpdateCropZoneLevelArgs = {
  input: PutCropZoneLevelInput;
};

export type NerpQuery = {
  __typename?: 'NerpQuery';
  acresByFarm: Scalars['Float'];
  cO2ByOrganization: Scalars['Float'];
  cropzoneProjectExists: Scalars['Boolean'];
  emissionsReductionKgCo2E: Scalars['Float'];
  getCropZoneDetails?: Maybe<CropZoneDetail>;
  getCropZoneTable: Array<CropZoneTableResult>;
  getCropZoneTable__count: Scalars['Int'];
  getCropzoneYears: Array<Scalars['Int']>;
  getNerpProjectDetails: ProjectSummary;
  getNerpProjectOrganizationsDetails: ProjectOrganizationsDetailsResponse;
  getNerpProjects: ProjectSummaryResponse;
  nitrogenUseKgs: Scalars['Float'];
  totalCredits: Scalars['Decimal'];
  totalEmissionKgN20: Scalars['Float'];
  verifiedAcresByOrganization: Scalars['Float'];
};


export type NerpQueryAcresByFarmArgs = {
  cropYear?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryCo2ByOrganizationArgs = {
  nerpProjectId: Scalars['Int'];
};


export type NerpQueryCropzoneProjectExistsArgs = {
  cropzoneIds: Array<Scalars['Int']>;
  projectId: Scalars['ID'];
};


export type NerpQueryEmissionsReductionKgCo2EArgs = {
  cropYear?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryGetCropZoneDetailsArgs = {
  cropZoneId: Scalars['Int'];
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryGetCropZoneTableArgs = {
  cropYear: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  projectId?: InputMaybe<Scalars['ID']>;
  protocolId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type NerpQueryGetCropZoneTable__CountArgs = {
  cropYear: Scalars['Int'];
  organizationId: Scalars['ID'];
  projectId?: InputMaybe<Scalars['ID']>;
  protocolId: Scalars['ID'];
  where?: InputMaybe<BinaryFilter>;
};


export type NerpQueryGetCropzoneYearsArgs = {
  organizationId: Scalars['ID'];
};


export type NerpQueryGetNerpProjectDetailsArgs = {
  projectId: Scalars['ID'];
};


export type NerpQueryGetNerpProjectOrganizationsDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nerpProjectId: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type NerpQueryGetNerpProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type NerpQueryNitrogenUseKgsArgs = {
  cropYear?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryTotalCreditsArgs = {
  cropYear?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryTotalEmissionKgN20Args = {
  cropYear?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type NerpQueryVerifiedAcresByOrganizationArgs = {
  nerpProjectId: Scalars['Int'];
};

export type ProjectOrganizationsDetails = {
  __typename?: 'ProjectOrganizationsDetails';
  lastUpdated: Scalars['DateTimeOffset'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  totalAcres?: Maybe<Scalars['Float']>;
  totalCredits?: Maybe<Scalars['Float']>;
  totalEmmissions?: Maybe<Scalars['Float']>;
  verifiedAcres?: Maybe<Scalars['Float']>;
  verifiedCredits?: Maybe<Scalars['Float']>;
  verifiedEmmisions?: Maybe<Scalars['Float']>;
};

export type ProjectOrganizationsDetailsResponse = {
  __typename?: 'ProjectOrganizationsDetailsResponse';
  projectOrganizationsDetails: Array<ProjectOrganizationsDetails>;
  projectOrganizationsDetails__count: Scalars['Int'];
};

export enum ProjectStatus {
  Created = 'CREATED',
  Locked = 'LOCKED',
  Reopened = 'REOPENED'
}

export type ProjectSummary = {
  __typename?: 'ProjectSummary';
  acres?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  cropYear?: Maybe<Scalars['Int']>;
  emissions?: Maybe<Scalars['Float']>;
  lastUpdated: Scalars['DateTimeOffset'];
  name: Scalars['String'];
  nerpProjectId: Scalars['Int'];
  organizations: Scalars['Int'];
  projectId: Scalars['ID'];
  status: ProjectStatus;
};

export type ProjectSummaryResponse = {
  __typename?: 'ProjectSummaryResponse';
  projectSummary: Array<ProjectSummary>;
  projectSummary__count: Scalars['Int'];
};

export type PutCropZoneLevelInput = {
  cropzoneId: Scalars['Int'];
  level: CropZoneResult4RLevel;
  protocolId: Scalars['ID'];
};

export type UpdateCropZoneLevelMutationVariables = Exact<{
  input: PutCropZoneLevelInput;
}>;


export type UpdateCropZoneLevelMutation = { __typename?: 'NerpMutation', updateCropZoneLevel: number };

export type AddCropzoneProjectMutationVariables = Exact<{
  input: AddOrganizationProjectInput;
}>;


export type AddCropzoneProjectMutation = { __typename?: 'NerpMutation', addCropzoneProject: string };

export type GetNerpOrganizationDashboardDataQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  organizationId: Scalars['ID'];
  cropYear?: InputMaybe<Scalars['Int']>;
}>;


export type GetNerpOrganizationDashboardDataQuery = { __typename?: 'NerpQuery', acresByFarm: number, nitrogenUseKgs: number, totalEmissionKgN20: number, emissionsReductionKgCo2E: number, totalCredits: any };

export type GetCropZoneTableQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  organizationId: Scalars['ID'];
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  cropYear: Scalars['Int'];
  projectId?: InputMaybe<Scalars['ID']>;
}>;


export type GetCropZoneTableQuery = { __typename?: 'NerpQuery', getCropZoneTable__count: number, getCropZoneTable: Array<{ __typename?: 'CropZoneTableResult', id: number, name?: string | null, cropName?: string | null, acres: number, latitude: number, longitude: number, ownership: string, landLocation: string, ecoDistrict?: string | null, tillage: string, irrigated: string, emissions: number, credits: any, lastUpdated?: any | null, level?: CropZoneResult4RLevel | null, totalNitrogenKg: number, totalYieldKg: number, projectName?: string | null, cropYear: number }> };

export type GetCropZoneDetailsQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  cropZoneId: Scalars['Int'];
  organizationId: Scalars['ID'];
}>;


export type GetCropZoneDetailsQuery = { __typename?: 'NerpQuery', getCropZoneDetails?: { __typename?: 'CropZoneDetail', id: number, geometryObject?: string | null, ownership?: string | null, boundary: { __typename?: 'Boundary', minX: number, minY: number, maxX: number, maxY: number } } | null };

export type CropzoneProjectExistsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  cropzoneIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CropzoneProjectExistsQuery = { __typename?: 'NerpQuery', cropzoneProjectExists: boolean };

export type GetCropzoneYearsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetCropzoneYearsQuery = { __typename?: 'NerpQuery', getCropzoneYears: Array<number> };

export type GetNerpProjectsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
}>;


export type GetNerpProjectsQuery = { __typename?: 'NerpQuery', getNerpProjects: { __typename?: 'ProjectSummaryResponse', projectSummary__count: number, projectSummary: Array<{ __typename?: 'ProjectSummary', nerpProjectId: number, projectId: string, name: string, cropYear?: number | null, status: ProjectStatus, emissions?: number | null, acres?: number | null, credits?: number | null, organizations: number, lastUpdated: any }> } };

export type GetNerpProjectOrganizationsDetailsQueryVariables = Exact<{
  nerpProjectId: Scalars['Int'];
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetNerpProjectOrganizationsDetailsQuery = { __typename?: 'NerpQuery', verifiedAcresByOrganization: number, cO2ByOrganization: number, getNerpProjectOrganizationsDetails: { __typename?: 'ProjectOrganizationsDetailsResponse', projectOrganizationsDetails__count: number, projectOrganizationsDetails: Array<{ __typename?: 'ProjectOrganizationsDetails', organizationId: string, name: string, verifiedEmmisions?: number | null, totalEmmissions?: number | null, verifiedAcres?: number | null, totalAcres?: number | null, verifiedCredits?: number | null, totalCredits?: number | null, lastUpdated: any }> } };

export type GetNerpProjectDetailsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetNerpProjectDetailsQuery = { __typename?: 'NerpQuery', getNerpProjectDetails: { __typename?: 'ProjectSummary', nerpProjectId: number, projectId: string, name: string, cropYear?: number | null, status: ProjectStatus, emissions?: number | null, acres?: number | null, credits?: number | null, organizations: number, lastUpdated: any } };

export const UpdateCropZoneLevelDocument = gql`
    mutation UpdateCropZoneLevel($input: PutCropZoneLevelInput!) {
  updateCropZoneLevel(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCropZoneLevelGQL extends Apollo.Mutation<UpdateCropZoneLevelMutation, UpdateCropZoneLevelMutationVariables> {
    override document = UpdateCropZoneLevelDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCropzoneProjectDocument = gql`
    mutation AddCropzoneProject($input: AddOrganizationProjectInput!) {
  addCropzoneProject(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCropzoneProjectGQL extends Apollo.Mutation<AddCropzoneProjectMutation, AddCropzoneProjectMutationVariables> {
    override document = AddCropzoneProjectDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNerpOrganizationDashboardDataDocument = gql`
    query GetNerpOrganizationDashboardData($protocolId: ID!, $organizationId: ID!, $cropYear: Int) {
  acresByFarm(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
  )
  nitrogenUseKgs(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
  )
  totalEmissionKgN20(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
  )
  emissionsReductionKgCo2E(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
  )
  totalCredits(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNerpOrganizationDashboardDataGQL extends Apollo.Query<GetNerpOrganizationDashboardDataQuery, GetNerpOrganizationDashboardDataQueryVariables> {
    override document = GetNerpOrganizationDashboardDataDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCropZoneTableDocument = gql`
    query GetCropZoneTable($protocolId: ID!, $organizationId: ID!, $where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int, $cropYear: Int!, $projectId: ID) {
  getCropZoneTable(
    protocolId: $protocolId
    organizationId: $organizationId
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    cropYear: $cropYear
    projectId: $projectId
  ) {
    id
    name
    cropName
    acres
    latitude
    longitude
    ownership
    landLocation
    ecoDistrict
    tillage
    irrigated
    emissions
    credits
    lastUpdated
    level
    totalNitrogenKg
    totalYieldKg
    projectName
    cropYear
  }
  getCropZoneTable__count(
    protocolId: $protocolId
    organizationId: $organizationId
    cropYear: $cropYear
    where: $where
    projectId: $projectId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCropZoneTableGQL extends Apollo.Query<GetCropZoneTableQuery, GetCropZoneTableQueryVariables> {
    override document = GetCropZoneTableDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCropZoneDetailsDocument = gql`
    query GetCropZoneDetails($protocolId: ID!, $cropZoneId: Int!, $organizationId: ID!) {
  getCropZoneDetails(
    protocolId: $protocolId
    cropZoneId: $cropZoneId
    organizationId: $organizationId
  ) {
    id
    geometryObject
    ownership
    boundary {
      minX
      minY
      maxX
      maxY
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCropZoneDetailsGQL extends Apollo.Query<GetCropZoneDetailsQuery, GetCropZoneDetailsQueryVariables> {
    override document = GetCropZoneDetailsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CropzoneProjectExistsDocument = gql`
    query cropzoneProjectExists($projectId: ID!, $cropzoneIds: [Int!]!) {
  cropzoneProjectExists(projectId: $projectId, cropzoneIds: $cropzoneIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CropzoneProjectExistsGQL extends Apollo.Query<CropzoneProjectExistsQuery, CropzoneProjectExistsQueryVariables> {
    override document = CropzoneProjectExistsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCropzoneYearsDocument = gql`
    query GetCropzoneYears($organizationId: ID!) {
  getCropzoneYears(organizationId: $organizationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCropzoneYearsGQL extends Apollo.Query<GetCropzoneYearsQuery, GetCropzoneYearsQueryVariables> {
    override document = GetCropzoneYearsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNerpProjectsDocument = gql`
    query GetNerpProjects($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int, $protocolId: ID!) {
  getNerpProjects(
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    protocolId: $protocolId
  ) {
    projectSummary {
      nerpProjectId
      projectId
      name
      cropYear
      status
      emissions
      acres
      credits
      organizations
      lastUpdated
    }
    projectSummary__count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNerpProjectsGQL extends Apollo.Query<GetNerpProjectsQuery, GetNerpProjectsQueryVariables> {
    override document = GetNerpProjectsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNerpProjectOrganizationsDetailsDocument = gql`
    query GetNerpProjectOrganizationsDetails($nerpProjectId: Int!, $where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  getNerpProjectOrganizationsDetails(
    nerpProjectId: $nerpProjectId
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
  ) {
    projectOrganizationsDetails {
      organizationId
      name
      verifiedEmmisions
      totalEmmissions
      verifiedAcres
      totalAcres
      verifiedCredits
      totalCredits
      lastUpdated
    }
    projectOrganizationsDetails__count
  }
  verifiedAcresByOrganization(nerpProjectId: $nerpProjectId)
  cO2ByOrganization(nerpProjectId: $nerpProjectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNerpProjectOrganizationsDetailsGQL extends Apollo.Query<GetNerpProjectOrganizationsDetailsQuery, GetNerpProjectOrganizationsDetailsQueryVariables> {
    override document = GetNerpProjectOrganizationsDetailsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNerpProjectDetailsDocument = gql`
    query GetNerpProjectDetails($projectId: ID!) {
  getNerpProjectDetails(projectId: $projectId) {
    nerpProjectId
    projectId
    name
    cropYear
    status
    emissions
    acres
    credits
    organizations
    lastUpdated
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNerpProjectDetailsGQL extends Apollo.Query<GetNerpProjectDetailsQuery, GetNerpProjectDetailsQueryVariables> {
    override document = GetNerpProjectDetailsDocument;
    override client = 'nerp';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }