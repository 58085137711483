import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from "@angular/common/http";
import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from "@angular/core";
import {first, Observable, tap} from "rxjs";
import {handleApolloTemplateError, handleApolloTemplateSuccess} from "../../utils";
import {ToastrService} from "ngx-toastr";
import {ContentService} from "../../services/content.service";
import {TIdService} from '../../../@auth/services/tId.service';
import {switchMap} from 'rxjs/operators';


@Component({
    selector: 'ccx-file-upload',
    styleUrls: ['./ccx-file-upload.component.scss'],
    template: `
        <div class="file-upload__container">
            <div class="drop-zone">
                <modus-file-dropzone
                    #fileUpload
                    aria-Label="dropzone"
                    description="{{description}}"
                    [dropzoneHeight]="dropzoneHeight"
                    [dropzoneWidth]="dropzoneWidth"
                    label="{{title}}"
                    multiple="{{false}}"
                    (files)="onFileSelected($event)">
                </modus-file-dropzone>
            </div>
            <div class="progress-bar">
                <modus-progress-bar
                    [value]="uploadProgress"
                    max-value="100"
                    min-value="0"
                    size="small">
                </modus-progress-bar>
            </div>
        </div>
    `
})
export class CcxFileUploadComponent implements OnInit {

    @Input() description!: string;
    @Input() title!: string;
    @Input() url!: string;
    @Input() dropzoneHeight!: string;
    @Input() dropzoneWidth!: string;
    @ViewChild('fileUpload') fileUpload: any;
    @Output() responseReceived = new EventEmitter<Object | null>();
    @Input() formData!: { key: string, value: string }[];

    uploadProgress!: any;
    file!: any;
    fileName = '';
    isRemovingFilesFromDropZone = false;

    constructor(private httpClient: HttpClient,
                private toast: ToastrService,
                private contentSrv: ContentService,
                private tidService: TIdService) {
    }

    ngOnInit(): void {
    }

    onFileSelected(event: any) {
        const file = event.detail[0];

        if (file && file.length > 1 && !this.isRemovingFilesFromDropZone) {
            this.isRemovingFilesFromDropZone = true;
        }

        if (this.isRemovingFilesFromDropZone) {
            if (file.length === 1) {
                this.isRemovingFilesFromDropZone = false;
            }
            this.fileUpload.removeFile(file[0].name);
            return;
        }

        if (file && file.length > 0 && !this.isRemovingFilesFromDropZone) {

            this.fileName = file[0].name
            this.file = file;

            const formData = new FormData();
            this.formData.forEach((e) => {
                formData.append(e.key, new Blob([e.value], {type: 'application/json'}));
            });
            formData.append('file', file[0], file[0].name);

            if (this.uploadProgress == 100) {
                this.reset()
            }

            this.tidService.tokenStream().pipe(
                first(),
                switchMap(t => {
                    const headers = new HttpHeaders({
                        Authorization: `Bearer ${t?.tokens?.token || ''}`
                    });
                    return this.httpClient.post(this.url, formData, {
                        reportProgress: true,
                        observe: 'events',
                        headers: headers
                    });
                })
            ).subscribe(
                {
                    next: response => {
                        if (response && response.type === HttpEventType.UploadProgress) {
                            const total = response.total || 1;
                            let totalLoaded = Math.round(100 * (response.loaded / total));
                            this.uploadProgress = totalLoaded;
                        }
                        if (response && response.type === HttpEventType.Response) {
                            this.responseReceived.emit(response.body)
                        }
                    },
                    error: error => {
                        this.reset();
                        this.fileUpload.removeFile(this.fileName);
                        handleApolloTemplateError(error, this.toast, this.contentSrv);
                    },
                    complete: () => {
                        this.fileUpload.removeFile(this.fileName);
                        handleApolloTemplateSuccess({
                            templateId: 'SUCCESS_FILE_UPLOAD',
                            parameters: {}
                        }, this.toast, this.contentSrv);
                    }
                }
            );
        }
    }

    reset() {
        this.uploadProgress = null;
    }
}
