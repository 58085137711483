import { data } from 'src/app/Components/data-table/DataTableOptions';
import { GridDataModel } from '../../modus-select/modus-select.component';
import { DocumentManagerState } from './document-manager.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { formatFileSize } from 'src/app/@core/utils';
import { ChangeLogRow } from 'src/app/modules/protocol-nerp/models';

export interface OrganizationDocumentRow extends data {
    documentId: string;
    type: string;
    name: string;
    fileName: string;
    size: string;
    status: string;
    lastUpdated: string;
    id: number;
}

export const selectDocumentManagerState =
    createFeatureSelector<DocumentManagerState>('documentManager');

export const selectIsWorking = createSelector(
    selectDocumentManagerState,
    (state) => state.isWorking
);

export const selectIsPending = createSelector(
    selectDocumentManagerState,
    (state) => state.isWorking
);


export const selectProtocolFileUploadDialogOpen = createSelector(
    selectDocumentManagerState,
    (state) => state.fileUploadDialogOpen
);

export const selectOrganizationDocumentFilesCategories = createSelector(
    selectDocumentManagerState,
    (state) => state.fileCategory
);




export const selectOrganizationDocumentList = createSelector(
    selectDocumentManagerState,
    (state) => state.OrganizationDocumentlList
);


export const getOrganizationDocumentGrid = createSelector(
    selectOrganizationDocumentList,
    (projectDocuments): GridDataModel<OrganizationDocumentRow> => {
        if (!projectDocuments || !projectDocuments.projectDocuments) {
            return {
                data: [],
                count: 0
            };
        }

        const list = projectDocuments
            .projectDocuments.map(e => {
                const fileName = e.fileName.split('\\')[2];
                return {
                    id: e.id,
                    documentId: e.documentId,
                    blobIdentifier: e.blobIdentifier,
                    type: e.type,
                    name: e.name,
                    fileName: fileName,
                    size: formatFileSize(e.size),
                    status: e.status,
                    _id: e.type,
                    projectsCount: e.projectsCount,
                    _selected: false,
                    active: true,
                    lastUpdated: e.lastUpdated
                };
            }) || [];
        return {
            data: list,
            count: projectDocuments.projectDocuments__count
        }
    }
)

export const selectLinkToProjectDialogOpen = createSelector(
    selectDocumentManagerState,
    (state) => state.linkToProjectDialogOpen
);

export const selectDocumentSelected = createSelector(
    selectDocumentManagerState,
    (state) => state.documentSelected
);

export const selectOrganizationSelectedProject = createSelector(
    selectDocumentManagerState,
    (state) => state.organizationSelectedProject
);


/*

Upload Document Modal

*/

export const selectDocumentUploadIsWaiting = createSelector(
    selectDocumentManagerState,
    (state) => state.isDocumentUploadWorking
)

export const selectIsLinkToProjectWorking = createSelector(
    selectDocumentManagerState,
    (state) => state.isLinkToProjectWorking
)

export const selectDocumentUploadValidationErrors = createSelector(
    selectDocumentManagerState,
    (state) => state.documentSubmitAsyncErrors
)

export const selectEditDocumentDialogOpen = createSelector(
    selectDocumentManagerState,
    (state) => state.editDocumentDialogOpen
);

export const selectIsEditDocumentWorking = createSelector(
    selectDocumentManagerState,
    (state) => state.isEditDocumentWorking
)

export const selectChangeLogModalDialogState = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogModalDialogOpen
);

export const selectChangeLogModalDocumentId = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogSelectedDocumentId
);

export const selectChangeLogList = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogGridData
);


export const selectChangeLogGrid = createSelector(
    selectChangeLogList,
    (state): GridDataModel<ChangeLogRow> => {
        if (!state) {
            return {
                data: [],
                count: 0
            };
        }
        const list: ChangeLogRow[] = state.getDocumentChangeLogs.map(e => {

            const fileName = e.blobIdentifier?.split('\\')[2];
            return {
                id: e.id,
                documentId: e.documentId,
                time: e.time,
                change: e.change,
                user: e.user,
                fileName: fileName,
                blobIdentifier: e.blobIdentifier ?? '',
                _id: e.id,
                _selected: false,
            };
        }) || [];

        return {
            data: list,
            count: state.documentChangeLogs__count
        }
    }
)
export const selectDocumentsStatusValidationList = createSelector(
    selectDocumentManagerState,
    (state) => state.DocumentsStatusValidation
);


