import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { NerpProtocolState } from './protocol-nerp.reducer';
import { NerpCropzoneProjectRow, NerpOrganizationRow } from '../models';
import { GridDataModel } from 'src/app/@core/components/ccx-grid/ccx-grid.component';
import { GetCropzoneYearsQuery } from 'src/app/@core/generated/operations-protocol-nerp-graphql';

export const selectNerpProtocolState = createFeatureSelector<NerpProtocolState>("NerpProtocol");

export const selectProjectList = createSelector(
    selectNerpProtocolState,
    (albNerp) => albNerp.nerpProjectsList
);


export const getProjectList = createSelector(
    selectProjectList,
    (apiConns: NerpCropzoneProjectRow[] | null): GridDataModel<NerpCropzoneProjectRow> => {
        if (!apiConns) {
            return {
                data: [],
                count: 0
            };
        }
        const list: NerpCropzoneProjectRow[] = [
            {
                name: "Project 1",
                status: 'Created',
                acres: '26,954',
                organizations: 25,
                cropyear: 2021,
                credits: 12.8,
                emissions: '128,256',
                lastupdated: '2024-09-06T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 2",
                status: 'Created',
                acres: '626,015',
                organizations: 78,
                cropyear: 2020,
                credits: 55,
                emissions: '310',
                lastupdated: '2020-05-06T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 3",
                status: 'Created',
                acres: '345,678',
                organizations: 35,
                cropyear: 2021,
                credits: 78,
                emissions: '102',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 4",
                status: 'Created',
                acres: '8,578',
                organizations: 9,
                cropyear: 2021,
                credits: 63,
                emissions: '800',
                lastupdated: '2023-01-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 5",
                status: 'Created',
                acres: '5,678',
                organizations: 44,
                cropyear: 2021,
                credits: 57,
                emissions: '987',
                lastupdated: '2022-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 6",
                status: 'Created',
                acres: '69,658',
                organizations: 24,
                cropyear: 2021,
                credits: 14,
                emissions: '365',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 7",
                status: 'Created',
                acres: '357,841',
                organizations: 36,
                cropyear: 2021,
                credits: 67,
                emissions: '47',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 8",
                status: 'Closed',
                acres: '23,678',
                organizations: 97,
                cropyear: 2021,
                credits: 11,
                emissions: '657',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 9",
                status: 'Created',
                acres: '36,528',
                organizations: 55,
                cropyear: 2021,
                credits: 8,
                emissions: '250',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 10",
                status: 'Closed',
                acres: '12,000',
                organizations: 55,
                cropyear: 2021,
                credits: 91,
                emissions: '9',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 11",
                status: 'Created',
                acres: '945,378',
                organizations: 13,
                cropyear: 2024,
                credits: 30,
                emissions: '256',
                lastupdated: '2024-03-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            },
            {
                name: "Project 12",
                status: 'Created',
                acres: '940,668',
                organizations: 65,
                cropyear: 2021,
                credits: 8,
                emissions: '9',
                lastupdated: '2021-08-10T22:32:16.6964068+00:00',
                _id: 1,
                _selected: undefined
            }
        ]
        return {
            data: list,
            count: 0
        }
    })

export const selectCalculationList = createSelector(
    selectNerpProtocolState,
    (albNerp) => albNerp.nerpCalculationList
);

export const getCalculationList = createSelector(
    selectCalculationList,
    (state) => {
        if (!state) {
            return {
                data: [],
                count: 0
            };
        }
        const list = state.getCropZoneTable.map(e => {
            return {
                id: e.id,
                name: e.name,
                cropName: e.cropName,
                ownership: e.ownership,
                acres: e.acres,
                latitude: e.latitude,
                longitude: e.longitude,
                landLocation: e.landLocation,
                ecoDistrict: e.ecoDistrict,
                irrigated: e.irrigated,
                tillage: e.tillage,
                emissions: e.emissions,
                credits: e.credits,
                lastUpdated: e.lastUpdated,
                level: e.level,
                totalNitrogenKg: e.totalNitrogenKg,
                totalYieldKg: e.totalYieldKg,
                projectName: e.projectName,
                cropYear: e.cropYear,
                _id: e.id,
                _selected: false
            };
        }) || [];
        return {
            data: list,
            count: state.getCropZoneTable__count
        }
    }
)

export const getCropZoneDetail = createSelector(
    selectNerpProtocolState,
    (albNerp) => {
        return albNerp.nerpCropZoneDetail?.getCropZoneDetails ?? null;
    }
);

export const selectCropZoneDialogOpen = createSelector(
    selectNerpProtocolState,
    (state) => state.cropZoneDialogOpen
);

export const selectCropZoneDetailAndDialogOpen = createSelector(
    getCropZoneDetail,
    selectCropZoneDialogOpen,
    (cropZoneDetail, dialogOpen) => {
        return {
            cropZoneDetail,
            dialogOpen
        }
    }
)


export const selectIsPending = createSelector(
    selectNerpProtocolState,
    (albNerp) => albNerp.isWorking
);




export const selectProjectNames = createSelector(
    selectNerpProtocolState,
    (albNerpProj) => albNerpProj.projectNamesFilter
);


export const selectProjectDetailList = createSelector(
    selectNerpProtocolState,
    (albNerp) => albNerp.nerpProjectsDetailList
);


export const selectOrganizationsList = createSelector(
    selectNerpProtocolState,
    (nerpProtocol) => nerpProtocol.organizationsList
);

export const selectIsProtocolNerpCropZoneLevelUpdateLoadPending = createSelector(
    selectNerpProtocolState,
    (nerpProtocol) => nerpProtocol.isProtocolNerpCropZoneLevelUpdateLoadPending
);


export const getOrganizationsGrid = createSelector(
    selectOrganizationsList,
    (organizations: any | null): GridDataModel<NerpOrganizationRow> => {
        return {
            data: organizations,
            count: organizations.length
        }
    }
);

export const selectDashboardCounters = createSelector(
    selectNerpProtocolState,
    (state) => {
        return [
            {
                id: 1,
                count: state.dashboardCounters?.acresByFarm ?? 0,
                name: 'Total Acres'
            },
            {
                id: 2,
                count: state.dashboardCounters?.nitrogenUseKgs ?? 0,
                name: 'Nitrogen Use Kgs'
            },
            {
                id: 3,
                count: state.dashboardCounters?.totalEmissionKgN20 ?? 0,
                name: 'Total Emission Kg N20'
            },
            {
                id: 4,
                count: state.dashboardCounters?.emissionsReductionKgCo2E ?? 0,
                name: 'Emissions Reduction Kg Co2E'
            },
            {
                id: 5,
                count: state.dashboardCounters?.totalCredits ?? 0,
                name: 'Total Credits'
            }]

    }
);

export const selectIsCropZoneDialogWorking = createSelector(
    selectNerpProtocolState,
    (state) => state.isCropZoneDialogWorking
)

export const selectCropYears = createSelector(
    selectNerpProtocolState,
    (albNerp) => albNerp.cropYears
);

export const selectOrganizationSelectedProject = createSelector(
    selectNerpProtocolState,
    (state) => state.organizationSelectedProject
);

export interface CropYearsDropdownItem {
    id: string;
    name: string;
}

export const selectCropYearDropdownItems: 
    MemoizedSelector<
        object, 
        CropYearsDropdownItem[], 
        (s1: GetCropzoneYearsQuery) => CropYearsDropdownItem[]
    >
= createSelector(
    selectCropYears,
    (state) => {
        if (!state) {
            return [];
        }
        return state.getCropzoneYears.map(e => {
            return {
                id: e.toString(),
                name: e.toString()
            };
        }) || [];
    }
)


export const selectOrganizationSelectedCropYear:
    MemoizedSelector<
        object, 
        CropYearsDropdownItem, 
        (s1: NerpProtocolState) => CropYearsDropdownItem
    > 
= createSelector(
    selectNerpProtocolState,
    (state) => state.organizationSelectedYear
);
