import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SiteMapState} from './sitenav.reducer';
import {getRouterSelectors, RouterReducerState} from '@ngrx/router-store';

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectRouteDataParam, // factory function to select a route data param
    selectUrl, // select the current url
    selectTitle, // select the title if available
} = getRouterSelectors();

export const siteNavFeatureSelector = createFeatureSelector<SiteMapState>('siteNav');

export const selectRootSiteMap = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.ProtocolPageSiteMap;
    }
);

export const selectProtocolPages = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.ProtocolPages
    }
);

export const selectProtocolRootKey = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.protocolRootKey
    }
);


export const selectModuleValidation = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.ModuleValidation
    }
);

export const selectErrorPageInfo = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.PageErrorInfo
    }
);

export const selectStatusErrorPage = createSelector(
    siteNavFeatureSelector,
    state => {
        return state.moduleStatus
    }
);

export const selectPageByStatus = createSelector(
    siteNavFeatureSelector,
    state => {
        if (state.moduleStatus === null) {
            return state.PageErrorInfo.find(a => a?.pageTitle.trim() === "404");
        }
        return state.PageErrorInfo.find(a => a?.pageTitle.trim() === state.moduleStatus);
    }
);
export const selectedProtocolMenu = createSelector(
    siteNavFeatureSelector,
    (state) => {
        return state.ProtocolMenuActive;
    }
);

export const selectProtocolId = createSelector(
    selectCurrentRoute,
    (route) => {
        return route.params['protocolId'] ?? null as string | null;
    }
);

export const selectPowerBiWorkspaceId = createSelector(
    selectCurrentRoute,
    (route) => {
        return route.params['workspaceId'] ?? null as string | null;
    }
);

export const selectPowerBiReportId = createSelector(
    selectCurrentRoute,
    (route) => {
        return route.params['reportId'] ?? null as string | null;
    }
);

export const selectAllPowerBiParams = createSelector(
    selectProtocolId,
    selectPowerBiWorkspaceId,
    selectPowerBiReportId,
    (protocolId, workspaceId, reportId) => {
        return {protocolId, workspaceId, reportId};
    }
);

