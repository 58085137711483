import { Injectable } from "@angular/core";
import { CreateDocumentUploadLinkGQL } from "../generated/operations-core-graphql";
import { first, map, Observable } from "rxjs";

@Injectable()
export class DocumentService {



	constructor(readonly gqlCreateDocumentDownloadLink: CreateDocumentUploadLinkGQL
	) {

	}

	public downloadDocument(blobIdentifier: string) {

		this.gqlCreateDocumentDownloadLink.watch({ blobIdentifier })
			.valueChanges.pipe(
				first(),
				map(e => e.data.createDocumentUploadLink)
			).subscribe(url => {
				window.open(url, '_blank');
			})
	}

	 getDocumentDownloadLink(blobIdentifier: string): Observable<string> {
        return this.gqlCreateDocumentDownloadLink.watch({ blobIdentifier })
            .valueChanges.pipe(
                first(),
                map(e => e.data.createDocumentUploadLink)
            );
    }
}