import {ElementRef, Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {
    CdkScrollable, ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayPositionBuilder, OverlayRef, PositionStrategy,
    ScrollStrategy
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';

@Injectable()
export class CcxOverlayService {
    constructor(private overlay: Overlay) {
    }

    getPositionBuilder() : OverlayPositionBuilder {
        return this.overlay.position();
    }

    getScrollStrategy() : ScrollStrategy {
        return this.overlay.scrollStrategies.reposition();
    }

    getDefaultPositionStrategy(cellWrapperRef: ElementRef, parentComponent: CdkScrollable, positions?: ConnectedPosition[]): FlexibleConnectedPositionStrategy {
        const positionBuilder = this.getPositionBuilder();
        let connectedPositions: ConnectedPosition[] = positions || [{
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
        }];
        return this.getPositionBuilder()
            .flexibleConnectedTo(cellWrapperRef)
            .withScrollableContainers([parentComponent])
            .withFlexibleDimensions(false)
            .withPositions(connectedPositions)
            .withPush(true)
            .withViewportMargin(8);
    }

    createOverlay(overlayConfig: OverlayConfig): OverlayRef {
        overlayConfig = {
            ...overlayConfig,
            hasBackdrop: false,
            panelClass: 'ccx-overlay-panel',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        };
        return this.overlay.create(overlayConfig);
    }

    attachOverlay(templateRef: TemplateRef<unknown>, viewContainerRef: ViewContainerRef,
                  cellWrapperRef: ElementRef, parentComponent: CdkScrollable,
                  width: number | string, height?: number | string, positions?: ConnectedPosition[]): OverlayRef {
        const positionStrategy = this.getDefaultPositionStrategy(cellWrapperRef,
            parentComponent, positions);

        const overlayRef = this.createOverlay({
            positionStrategy,
            width,
            height
        });
        overlayRef.attach(new TemplatePortal(templateRef, viewContainerRef));
        return overlayRef;
    }
}
