import { Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CropZoneResult4RLevel, PutCropZoneLevelInput } from 'src/app/@core/generated/operations-protocol-nerp-graphql';
import { State } from 'src/app/state/app.state';
import { nerpActions } from '../state';

@Component({
    selector: 'app-cropzone-radio-renderer',
    template: `
        <div class="cropzone-radio-container">
            <label class="cropzone-radio-container-label" *ngFor="let option of options">
                <input type="radio" [value]="option" [checked]="option === params.value" (change)="onRadioChange(option)" />
                {{ option }}
            </label>
        </div>
    `,
    styles: [
        `
            .cropzone-radio-container {
                display: flex;
                flex-wrap: wrap;
                gap: 0 15px;
            }

            .cropzone-radio-container-label {
                display: flex;
                font-size: .75em;
                gap: 3px;
                align-items: center;
                font-size: 0.7em;
            }
        `,
    ],
})
export class CropZoneLevelRadioRendererComponent implements ICellRendererAngularComp {
    private store = inject(Store<State>);

    options = [
        CropZoneResult4RLevel.Basic,
        CropZoneResult4RLevel.Intermediate,
        CropZoneResult4RLevel.Advanced
    ];

    params: ICellRendererParams;
    cropZoneId: number;
    protocolId: string;

    agInit(params: any): void {
        this.params = params;
        this.cropZoneId = params.data.id;
        this.protocolId = params.protocolId;
    }

    refresh(params: any): boolean {
        this.params = params;
        this.cropZoneId = params.data.id;
        this.protocolId = params.protocolId;
        return true;
    }

    public invokeParentMethod() {
        this.params.context.componentParent.linkMethodParent(this.params.node);
    }

    onRadioChange(selectedValue: any) {
        this.params.node.setDataValue(this.params.colDef.field, selectedValue);
        this.onLevelChange({ detail: { value: selectedValue } });
    }

    onLevelChange(event: any) {
        const input: PutCropZoneLevelInput = {
            protocolId: this.protocolId,
            cropzoneId: this.cropZoneId,
            level: event.detail.value,
        }
        this.store.dispatch(nerpActions.protocolNerpCropZoneLevelUpdateLoad({ input }));
    }
}
