<div class="label-container" *ngIf="!requiredWithoutSymbol">
  <div [ngClass]="_settings.labelSize"><label>{{ label }}</label><span *ngIf="required" class="required">*</span></div>
  <div *ngIf="this._settings.smallItemWidth">
    <ng-template [ngTemplateOutlet]="labelChips"></ng-template>
  </div>
</div>
<ng-template  *ngIf="!this._settings.smallItemWidth" [ngTemplateOutlet]="labelChips"></ng-template>

<div tabindex="0" (blur)="onTouched()" class="multiselect-dropdown" (clickOutside)="closeDropdown()">
  <div [class.disabled]="disabled">
    <span tabindex="-1" class="dropdown-btn" (click)="toggleDropdown($event)">
      <span class="placeholderTexAt">{{_settings.showSelectedItemsAt?  selectedItemsText : _settings.searchPlaceholderText }}</span>
      <span [ngClass]="{ 'dropdown-multiselect--active': _settings.defaultOpen }"
        style="float:right !important;padding-right:4px">
        <span style="padding-right: 15px;" *ngIf="itemShowRemaining()>0">...</span>
        <span class="dropdown-multiselect__caret">
          <svg class="icon-chevron-down-thick" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="24"
            width="24" viewBox="0 0 24 24">
            <path
              d="M15.88 9.29 12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0z">
            </path>
          </svg>
        </span>
      </span>
    </span>
  </div>
  <div class="dropdown-list" [hidden]="!_settings.defaultOpen">
    <ul class="item1">
      <li (click)="toggleSelectAll()"
        *ngIf="(_data.length > 0 || _settings.allowRemoteDataSearch) && !_settings.singleSelection && _settings.enableCheckAll && _settings.limitSelection===-1"
        class="multiselect-item-checkbox" style="border-bottom: 1px solid #ccc;padding:10px">
        <!--        <input type="checkbox" aria-label="multiselect-select-all" [checked]="isAllItemsSelected()" [disabled]="disabled || isLimitSelectionReached()" />-->
        <modus-checkbox class="checkbox" [checked]="isAllItemsSelected()"
          [disabled]="disabled || isLimitSelectionReached()"></modus-checkbox>
        <div>{{ !isAllItemsSelected() ? _settings.selectAllText : _settings.unSelectAllText }}</div>
      </li>
      <li class="filter-textbox"
        *ngIf="(_data.length>0 || _settings.allowRemoteDataSearch) && _settings.allowSearchFilter">
        <input type="text" aria-label="multiselect-search" [readOnly]="disabled"
          [placeholder]="_settings.searchPlaceholderText" [(ngModel)]="filter.text"
          (ngModelChange)="onFilterTextChange($event)">
      </li>
    </ul>
    <ul class="item2" [style.maxHeight]="_settings.maxHeight+'px'">
      <li *ngFor="let item of _data | multiSelectFilter:filter; let i = index;" (click)="onItemClick($event,item)"
        class="multiselect-item-checkbox">
        <!--        <input type="checkbox" [attr.aria-label]="item.text" [checked]="isSelected(item)" [disabled]="disabled || (isLimitSelectionReached() && !isSelected(item)) || item.isDisabled" />-->
        <modus-checkbox [label]="item.text" [attr.aria-label]="item.text" [checked]="item.checked" (checkboxClick)="isSelected(item)"
          [disabled]="disabled || (isLimitSelectionReached() && !isSelected(item)) || item.isDisabled"></modus-checkbox>
      </li>
      <li class='no-filtered-data'
        *ngIf="_data.length != 0 && (_data | multiSelectFilter:filter).length == 0 && !_settings.allowRemoteDataSearch">
        <h5>{{ _settings.noFilteredDataAvailablePlaceholderText }}</h5>
      </li>
      <li class='no-data' *ngIf="_data.length == 0 && !_settings.allowRemoteDataSearch">
        <h5>{{ _settings.noDataAvailablePlaceholderText }}</h5>
      </li>
    </ul>
  </div>
</div>


<ng-template #labelChips>
  <span *ngFor="let item of selectedItems; trackBy: trackByFn;let k = index" class="selected-item-container">
    <span class="selected-item" *ngIf="k  <= (this._settings.itemsShowLimit-1)">
      <span>{{ item.text }}&nbsp;</span>
      <a (click)="onItemClick($event,item)">
        <i class="modus-icons notranslate" aria-hidden="true" >close</i>
      </a>
    </span>
  </span>
</ng-template>