<div class="modal-content">

    <div class="modal-header">
        <h5 *ngIf="secondaryButtonText">{{ headerText | transloco }}</h5>
        <h3 *ngIf="!secondaryButtonText">{{ headerText | transloco }}</h3>

        <button (click)="dialogClose()">
            <svg class="icon-close" height="20" width="20" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path
                        d="M19 7.30929L17.6907 6L12.5 11.1907L7.30929 6L6 7.30929L11.1907 12.5L6 17.6907L7.30929 19L12.5 13.8093L17.6907 19L19 17.6907L13.8093 12.5L19 7.30929Z"
                        fill="#6A6976"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
        </button>
    </div>
    <div class="modal-body-content">
        <div class="subheader-text">{{ subHeaderText }}</div>
    </div>

    <div class="modal-footer">
        <ccx-button *ngIf="secondaryButtonText" height="2rem" buttonStyle="outline" color="secondary"
            (buttonClick)="handleSecondaryButton()">
            {{ secondaryButtonText }}
        </ccx-button>
        <ccx-button height="2rem" *ngIf="isDelete ; else notDelete" type="submit"
            [disabled]="submitDisabled" class="button" size="medium" color="danger"
            (buttonClick)="handlePrimaryAction()">
            {{ primaryButtonText }}
        </ccx-button>
        <ng-template #notDelete>
            <ccx-button [width]="secondaryButtonText ? 'auto' : '6rem'" [height]="secondaryButtonText ? '2rem' : '3rem'" type="submit" [disabled]="submitDisabled" class="button" size="medium"
                color="primary" (buttonClick)="handlePrimaryAction()">
                {{ primaryButtonText }}
            </ccx-button>
        </ng-template>
    </div>
</div>