<div class="modal-content">
    <app-spinner [isWorking]="isWorking()"></app-spinner>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Link / Unlink Projects' | transloco }}</h4>
        <modus-button size="25" button-style="borderless" color="primary" icon-only="close" aria-label="close"
            (buttonClick)="dialogClose()"></modus-button>
    </div>
    <div class="modal-body" cdk-scrollable>

        <div class="document-details">
            <div>Document Type</div>
            <div>{{documentType}}</div>
            <div>Document Name</div>
            <div>{{documentName}}</div>
            <div>File Name</div>
            <div>{{fileName}}</div>
        </div>
        <div>
            <h5 class="modal-title">Linked Projects</h5>
            <modus-list class="projects-list">
                <div class="projects-list-item">
                    <input [name]="defaultProjectSelected.name" [disabled]="disableDefaultOption" [value]="defaultProjectSelected.id"
                        type="checkbox" class="checkbox" id="idinput" [ngModel]="defaultOptionChecked"
                         (ngModelChange)="defaultOptionCheckedChange()" />
                         
                    <label class="label-for-check" for="idinput">{{defaultProjectSelected.name}}</label>
                </div>

                <div class="projects-list-item" *ngFor="let row of projectsList">
                    <input [name]="row.name" [value]="row.id" [disabled]="row.status === projectLockedStatus"
                        type="checkbox" class="checkbox" [checked]="row.checked" id="idinput" (change)="onCheckChange($event)" />
                    <label class="label-for-check" for="idinput">{{row.name}}</label>
                </div>
            </modus-list>
        </div>

    </div>

</div>
<div class="modal-footer">
    <ccx-button size="medium" buttonStyle="outline" color="secondary" (buttonClick)="dialogClose()">{{ 'Cancel'|
        transloco }}
    </ccx-button>
    <ccx-button 
    width="7.3125rem"
     size="medium"
      color="primary"

     [disabled]="!!(isSubmitDisabled | async)"

     (buttonClick)="linkToProject()">
        {{ 'OK'| transloco }}
    </ccx-button>
</div>