import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProtocolState } from './protocol.reducer';
import {
    ProtocolsQuery,
    TemplateDetailQuery,
    TemplatesQuery,
    OrganizationsByProtocolQuery, ProtocolTemplate, UsersByProtocolQuery,
    ApiConnectionsByProtocolQuery,
    ProjectStatus,
} from 'src/app/@core/generated/operations-core-graphql';
import {
    ProtocolsGridRow,
    TemplateGridRow,
    ProtocolApiConnectionsGridRow,
    ParticipantOrganizationGridRow,
} from '../models';
import { GridDataModel } from 'src/app/@core/components/ccx-grid/ccx-grid.component';
import { CoefficientsGridRow } from 'src/app/@core/components/ccx-grid-editing.component/model/data-source-editing';
import { GridEditDataModel } from 'src/app/@core/components/ccx-grid-editing.component/ccx-grid-editing.component';
import { FeedlotProjectRow } from '../../protocol-beef/models';
import { UsersGridRow } from '../../users/models';
import { OrganizationGridRow } from '../organization/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CcxTableCellRendererComponent } from 'src/app/@core/components/ccx-table/ccx-table-cell-renderer.component';
import { CcxTableTextEditorCellRenderer } from 'src/app/@core/components/ccx-table/ccx-table-text-editor-cell-renderer.component';
import { ColDef } from 'ag-grid-community';
import { formFunctions } from 'src/app/@core/functions';

export const selectProtocolState =
    createFeatureSelector<ProtocolState>('Protocol');

export const selectIsPending = createSelector(
    selectProtocolState,
    (protocol) => protocol.isWorking
);

export const selectCurrentView = createSelector(
    selectProtocolState,
    (protocol) => protocol.activeView
);

export const selectCurrentTab = createSelector(
    selectProtocolState,
    (proj) => proj.currentTab
);
export const selectDataSourceProtocol = createSelector(
    selectProtocolState,
    (protocol) => protocol?.dataSourceProtocol
);
export const selectDataSourceProject = createSelector(
    selectProtocolState,
    (protocol) => protocol?.dataSourceProject
);
export const selectDataSourceOrganization = createSelector(
    selectProtocolState,
    (protocol) => protocol?.dataSourceOrganization
);
export const selectTabsCoefficients = createSelector(
    selectProtocolState,
    (protocol) => protocol?.tabsCoefficients
);
export const selectCoefficientsColumnConf = createSelector(
    selectProtocolState,
    (protocol) => protocol?.coefficientsColumnConf
);

export const selectCurrentProtocol = createSelector(
    selectProtocolState,
    (protocol) => protocol?.currentProtocol
);

export const selectCurrentProtocolId = createSelector(
    selectProtocolState,
    (protocol) => protocol?.currentProtocol.id
);

export const selectProtList = createSelector(
    selectProtocolState,
    (prot) => prot?.protocolsList
);

export const getProtocolsGrid = createSelector(
    selectProtList,
    (protList: ProtocolsQuery | null): GridDataModel<ProtocolsGridRow> => {
        if (!protList || !protList.protocols) {
            return {
                data: [],
                count: 0,
            };
        }
        const list =
            protList.protocols?.protocols.map((p) => {
                return {
                    id: p?.id || '0',
                    name: p?.name || '',
                    cMSContentRootKey: p?.cMSContentRootKey || '',
                    templateName: p?.templateName || '',
                    adminsNames: p?.admins?.map((admin) => admin.email).join(', ') || '',
                    admins: p?.admins || [],
                    projects_count: p?.projects_count || 0,
                    _id: p?.id || '0',
                    _selected: false,
                    Name: {
                        _type: 'link',
                        display: p?.name || '',
                        cmsKey: p?.cMSContentRootKey || '',
                    },
                    protocolFactorValue: p?.protocolFactorValue || [],
                    documentDefinition: p?.documentDefinition?.documentTypes || [],
                    documentGroups: p?.documentDefinition?.documentGroups || [],
                };
            }) || [];
        return {
            data: list,
            count: protList.protocols.protocols__count || 0,
        };
    }
);

export const selecttemplateList = createSelector(
    selectProtocolState,
    (prot) => prot.templateList
);

export const getTemplateGrid = createSelector(
    selecttemplateList,
    (templateList: TemplatesQuery | null): GridDataModel<TemplateGridRow> => {
        if (!templateList || !templateList.templates) {
            return {
                data: [],
                count: 0,
            };
        }
        const list =
            templateList.templates?.map((p) => {
                return {
                    id: p?.id || '0',
                    name: p?.name || '',
                    createdBy: p?.createdBy || '',
                    createdOn: p?.createdOn || '',
                    updatedBy: p?.updatedBy || '',
                    updatedOn: p?.updatedOn || '',
                    _id: p?.id || '0',
                    _selected: false,
                };
            }) || [];
        return {
            data: list,
            count: templateList.templates__count || 0,
        };
    }
);

//Details Protocol


export const SelectedTemplateDetails = createSelector(
    selectProtocolState,
    (prot) => prot.SelectedTemplateDetail
);

export const selectedTemplateIdName = createSelector(
    SelectedTemplateDetails,
    (template): { id: string | undefined, name: string | undefined } => {
        return {
            id: template?.id,
            name: template?.name,
        };
    }
);

export const selectedTemplateProtocolFactors = createSelector(
    SelectedTemplateDetails,
    template => template && template.factors
        ? template.factors.filter(e => e.scope === 1)
        : null);
export const selectedTemplateOrganizationFactors = createSelector(
    SelectedTemplateDetails,
    template => template && template.factors
        ? template.factors.filter(e => e.scope === 2)
        : null);
export const selectedTemplateProjectFactors = createSelector(
    SelectedTemplateDetails,
    template => template && template.factors
        ? template.factors.filter(e => e.scope === 3)
        : null);


export const getTemplateDetailProtocolFactor = createSelector(
    SelectedTemplateDetails,
    (
        templateDetail: ProtocolTemplate | null
    ): GridEditDataModel<CoefficientsGridRow> => {
        if (
            !templateDetail ||
            templateDetail.factors?.length == 0
        ) {
            return {
                data: [
                ],
            };
        } else {
            const list =
                templateDetail.factors?.map((p) => {
                    return {
                        id: p?.id || '0',
                        name: p?.name,
                        scope: p?.scope,
                        type: p?.type,
                        status: 'disabled',
                    };
                }) || [];
            return {
                data: list.filter((e) => e.scope == 1),
            };
        }
    }
);

export const selectProtocolOrgList = createSelector(
    selectProtocolState,
    (protocolOrganization) => protocolOrganization.protocolOrganizationList
);


export const getProtocolOrgGrid = createSelector(
    selectProtocolOrgList,
    (
        selectProtOrgList: OrganizationsByProtocolQuery | null
    ): GridDataModel<OrganizationGridRow> => {
        if (!selectProtOrgList || !selectProtOrgList.organizationsByProtocol) {
            return {
                data: [],
                count: 0,
            };
        }
        const list =
            selectProtOrgList.organizationsByProtocol?.map((e) => {

                const address = e.street + ' ' + e.city + ' ' + e.state + ' ' + e.postalZipCode + ' ' + e.organizationCountry.name;
                return {
                    Id: e?.id || 0,
                    name: e?.name || '',
                    address: address,
                    phone:e?.phone || '',
                    contactPerson:e?.contactPerson || '',
                    email:e?.email || '',
                    externalSourceID: e?.externalSourceId || '',
                    _id: e?.id || 0,
                    _selected: false,
                    active: true,
                };
            }) || [];
        return {
            data: list,
            count: selectProtOrgList.organizationsByProtocol__count || 0,
        };
    }
);

export const getProtocolActive = createSelector(
    selectProtocolState,
    (prot) => prot.ActiveProtocol
);
export const selectProtocolDetailSummary = createSelector(
    selectProtocolState,
    (prot) => prot.protocolDetailSummary
);

export const addProtocolTemplate = createSelector(
    selectProtocolState, (protocol) => {
        return protocol.addProtocolTemplateSelected;
    }
)

export const selectProtocolTemplateFactorsActiveTab = createSelector(
    selectProtocolState,
    (protocol) => protocol.protocolTemplateFactorActiveTab);

export const selectUsersList = createSelector(
    selectProtocolState,
    (protocol) => protocol.usersList
);

export const getUsersGrid = createSelector(
    selectUsersList,
    (userList: UsersByProtocolQuery | null): GridDataModel<UsersGridRow> => {
        if (!userList || !userList.usersByProtocol) {
            return {
                data: [],
                count: 0
            };
        }
        const list = userList.usersByProtocol?.map(e => {
            return {
                id: e?.id || "",
                email: e?.email || "",
                userName: e?.userName || "",
                role: e?.role || "",
                status: e?.status || "",
                action: e?.action || "",
                _id: e?.id || "0",
                _selected: false,
            }
        }) || [];
        return {
            data: list,
            count: userList.usersByProtocol__Count || 0
        }
    }
);

export const selectCurrentOrganizationDetails = createSelector(
    selectProtocolState,
    (protocol) => protocol?.currentOrganization
);
export const selectCurrentOrganizationId = createSelector(
    selectProtocolState,
    (protocol) => protocol.currentOrganization?.id ?? ''
);

export const selectAdvisorList = createSelector(
    selectProtocolState,
    (protocol) => protocol.advisorQuery
);

export const selectAddAdvisorDialogOpen = createSelector(
    selectProtocolState,
    (protocol) => protocol.addAdvisorDialogOpen);

export const getOrgInformationForAdvisor = createSelector(
    selectProtocolState,
    (protocol) => protocol.organizationAdvisorToAdd);

export const selectIsPendingAddAdvisor = createSelector(
    selectProtocolState,
    (protocol) => protocol.isWorkingAddAdvisor
);

export const selectProtocolSelected = createSelector(
    selectProtocolState,
    (prot) => prot.protocolSelected
);


export const selectApiConnectionsList = createSelector(
    selectProtocolState,
    (prot) => prot.apiConnectionsList
);

export const getApiConnsGrid = createSelector(
    selectApiConnectionsList,
    (apiConns: ApiConnectionsByProtocolQuery | null): GridDataModel<ProtocolApiConnectionsGridRow> => {
        if (!apiConns) {
            return {
                data: [],
                count: 0
            };
        }

        const list: ProtocolApiConnectionsGridRow[] = apiConns?.apiConnectionsByProtocol.apiConnections.map(e => {
            return {
                clientId: e?.clientId || "",
                clientName: e?.clientName || "",
                _selected: false,
                applicationName: e?.applicationName || "",
                Organizations_count: e?.organizations_count || 0,
                lastEditedBy: e?.lastEditedBy || "",
                lastEditedOn: e?.lastEditedOn || "",
                _id: e?.id || "",
            }
        }) || [];

        return {
            data: list,
            count: apiConns.apiConnectionsByProtocol.apiConnections__count || 0
        }
    }
);
export const selectIsPendingOrgParticipant = createSelector(
    selectProtocolState,
    (protocol) => protocol.isWorkingOrgParticipant);

export const selectOrgParticipantDialogOpen = createSelector(
    selectProtocolState,
    (protocol) => protocol.addOrgParticipanDialogOpen);


export const selectOrgParticipantList = createSelector(
    selectProtocolState,
    (protocolOrganization) => protocolOrganization.protocolOrganizationList
);

export const selectOrgParticipantListGrid = createSelector(
    selectOrgParticipantList,
    (
        selectProtOrgList: OrganizationsByProtocolQuery | null
    ): GridDataModel<ParticipantOrganizationGridRow> => {
        if (!selectProtOrgList || !selectProtOrgList.organizationsByProtocol) {
            return {
                data: [],
                count: 0,
            };
        }
        const list =
            selectProtOrgList.organizationsByProtocol?.map((e) => {
                return {
                    id: e?.id || '',
                    name: e?.name || '',
                    externalid: e?.externalSourceId || '',
                    internalid: e?.id || '',
                    _id: e?.id || '',
                    _selected: undefined
                };
            }) || [];
        return {
            data: list,
            count: selectProtOrgList.organizationsByProtocol__count || 0,
        };
    }

);

export const selectapiConnectionDetail = createSelector(
    selectProtocolState,
    (protocol) => protocol.apiConnectionDetail
);


export const selectOrganizationParticipantsAdded = createSelector(
    selectProtocolState,
    (prot) => prot.organizationParticipantsAdded
);


export const selectProtocolTemplateName = createSelector(
    selectProtocolState,
    state => {
        return state.ActiveProtocolTemplateName
    }
);

export const selectProtocolProjects = createSelector(
    selectProtocolState,
    state => state?.protocolProjects
);

export const selectProtocolProjectsDropdownItems = createSelector(
    selectProtocolState,
    state => [{ id: null, name: 'All projects', year: 0, status: ProjectStatus.Created }, 
        ...(state?.protocolProjects?.projectsByProtocol ?? []).map((project) => {
            const projectYear = new Date(project.periodEnd).getFullYear();
            return {
                id: project.id,
                name: project.name,
                year: projectYear,
                status: project.status,
            }
        })
    ]
);

export const selectProtocolFactorDefinitions = createSelector(
    selectProtocolState,
    (state) => state.protocolFactorDefinitions
);

export const selectCoefficientFactorForm = (keys: {
    name: string,
    control: FormControl
}[]) => createSelector(selectProtocolFactorDefinitions, (state): FormGroup | null => {
    const factors = state?.map((factor: { name: any; type: any; }) => {
        return {
            name: factor.name,
            type: factor.type,
        };
    });
    if (!factors) {
        return null;
    }
    const formGroup = new FormGroup({});
    formGroup.addControl('id', new FormControl<string | null>(null, Validators.required));
    keys.forEach((key) => {
        const abstractControl = formFunctions.cloneAbstractControl(key.control);
        formGroup.addControl(key.name, abstractControl);
    });

    factors.forEach((factor: { type: any; name: string; }) => {
        switch (factor.type) {
            case 'Numbers':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    [Validators.required,
                        Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]));
                break;
            case 'Percentage (%)':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    [Validators.required, Validators.min(0),
                        Validators.max(1), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]));
                break;
            case 'Alphanumeric':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    Validators.required));
                break;
        }
    });
    return formGroup;
});

export const selectCoefficientColumnDefs = (factorKeyFormControls: {
    name: string,
    control: FormControl
}[], colDefs: ColDef[]) => createSelector(selectProtocolFactorDefinitions, (state): null | ColDef[] => {
    const factors = state?.map((factor: { name: any; }) => {
        return {
            name: factor.name,
        };
    });
    if (!factors) {
        return null;
    }

    const columns: ColDef[] = factors.map((factor: { name: any; }) => {
        return {
            headerName: factor.name,
            field: factor.name,
            sortable: false,
            editable: true,
            cellRenderer: CcxTableCellRendererComponent,
            cellEditor: CcxTableTextEditorCellRenderer
        };
    });
    factorKeyFormControls.forEach((key) => {
        columns.unshift({
            headerName: key.name,
            field: key.name,
            sortable: false,
            editable: true,
            minWidth: 50,
            cellRenderer: CcxTableCellRendererComponent,
            cellEditor: CcxTableTextEditorCellRenderer
        });
    });
    return columns.concat(colDefs);
});

export const selectCoefficients = createSelector(
    selectProtocolState, state => state.currentCoefficients);

export const selectProtocolDocumentFilesCategories = createSelector(
    selectProtocolState, state => state.fileCategory);
