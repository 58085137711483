import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {DocumentManagerFile, DocumentManagerState} from '../+state/document-manager.reducer';
import {documentManagerSelectors} from '../index';

@Component({
    selector: 'ccx-document-manager-dataocean',
    template: `
    <app-spinner [isWorking]="isWorking$ | async"></app-spinner>
    <div *ngIf="(files$ | async) as files">
        <ccx-file-browser [files]="files"></ccx-file-browser>
    </div>
    `,
    styles: [``]
})
export class DocumentManagerDataOceanComponent implements OnInit {

    isWorking$!: Observable<boolean>
    files$!: Observable<DocumentManagerFile[]>;

    constructor(private store: Store<DocumentManagerState>) {
    }
    ngOnInit(): void {
        this.isWorking$ = this.store.select(documentManagerSelectors.selectIsWorking);
        // this.files$ = this.store.select(documentManagerSelectors.selectFiles);
    }

}
