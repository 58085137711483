import { Component, EventEmitter, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { combineLatestWith, Observable, of, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { LinkDocumentToOrganizationProjectsInput, ProjectStatus } from 'src/app/@core/generated/operations-core-graphql';
import { documentManagerActions, documentManagerSelectors } from 'src/app/@core/components/document-manager-shell';
import { protocolsSelectors } from 'src/app/modules/protocols/state';

interface Project {
    id: string;
    name: string;
    checked: boolean;
    status: ProjectStatus;
  }
  
@Component({
    selector: 'app-link-to-project-shell',
    templateUrl: './link-to-project-shell.component.html',
    styleUrls: ['./link-to-project-shell.component.scss']
})
export class LinkToProjectShellComponent implements OnInit, OnDestroy {
    
    private store = inject(Store<State>);
    projectsList!: Project[];
    private onDestroy$ = new EventEmitter<void>();
    documentId!: string;
    documentType!: string;
    documentName!: string;
    fileName!: string;
    
    projectLockedStatus = ProjectStatus.Locked;
    disableDefaultOption: boolean = true;
    defaultOptionChecked: boolean = true;
    initialProjectsSelected!: Project[];
    projectsSelected!: Project[];
    defaultProjectSelected!: Project;
    readonly protocol = this.store.selectSignal(protocolsSelectors.selectCurrentProtocol);
    readonly organization = this.store.selectSignal(protocolsSelectors.selectCurrentOrganizationDetails);
    isWorking = this.store.selectSignal(documentManagerSelectors.selectIsLinkToProjectWorking);
    
    isSubmitDisabled: Observable<boolean> = of(true);
    
    constructor(
        private dialogRef: DialogRef<any>,
        @Inject(DIALOG_DATA) private data: any ) {
        dialogRef.disableClose = true;

    }
    ngOnInit(): void {

        this.store.pipe(
            select(documentManagerSelectors.selectDocumentSelected),
            combineLatestWith(this.store.pipe(select(protocolsSelectors.selectProtocolProjects))),
        ).pipe(takeUntil(this.onDestroy$))
            .subscribe(([e, projects]) => {

                const projectsList = projects?.projectsByProtocol.map((project) => {
                    const checked = e?.document.projectDocuments?.find(x => x.projectId == project.id) ? true : false;
                    return {
                        id: project.id,
                        name: project.name,
                        checked: checked,
                        status: project.status
                    }
                });

                const defaultProject = projectsList?.find((x: { id: string; name: string; checked: boolean, status: ProjectStatus }) => x.checked) ?? { id: '', name: '', checked: false, status: ProjectStatus.Created };
                const defaultProjectIndex = projectsList?.indexOf(defaultProject) ?? -1;
                this.initialProjectsSelected = projectsList?.filter((x) => x.checked) ?? [];

                projectsList?.splice(defaultProjectIndex, 1);

                const fileName = e?.document.blobIdentifier.split('\\')[2] ?? '';

                this.projectsList = projectsList ?? [];
                this.projectsSelected = projectsList?.filter((x) => x.checked) ?? [];
                this.documentId = e?.document.id ?? '';
                this.documentType = e?.document.type.name ?? '';
                this.documentName = e?.document.name ?? '';
                this.fileName = fileName;
                this.defaultProjectSelected = defaultProject;
                if (this.projectsSelected.length > 0) {
                    this.disableDefaultOption = false;
                }
                else {
                    this.disableDefaultOption = true;
                }
            }
            );

        this.store.select(documentManagerSelectors.selectLinkToProjectDialogOpen).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(open => {
            if (!open) {
                this.dialogRef.close();
            }
        });
    }

    linkToProject() {

        if (this.defaultOptionChecked) {
            this.projectsSelected.push(this.defaultProjectSelected);
        }
        const input: LinkDocumentToOrganizationProjectsInput = {
            documentId: this.documentId,
            projectIds: this.projectsSelected.map(project => project.id),
            organizationId: this.organization()?.id || '',
            protocolId: this.protocol()?.id || ''
        };
        this.store.dispatch(documentManagerActions.protocolLinkToProjectLoad({ input: input }));

    }

    dialogClose() {
        this.store.dispatch(documentManagerActions.protocolLinkToProjectDialogOpen({ value: false }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.complete();
    }


    onCheckChange(event: any) {

        if (event.target.checked) {
            this.projectsSelected.push(
                {
                    id: event.target.value,
                    name: event.target.name,
                    checked: true,
                    status: event.target.status
                }
            );
            this.disableDefaultOption = false;
        }
        else {
            let i: number = 0;
            this.projectsSelected.forEach((project: { id: string; name: string; }) => {
                if (project.id == event.target.value) {
                    this.projectsSelected.splice(i, 1);
                    return;
                }

                i++;
            });
        }

        if (this.projectsSelected.length == 0 && !this.disableDefaultOption) {
            this.disableDefaultOption = true;
            this.defaultOptionChecked = true;
        }
        this.inputChanged();

    }

    defaultOptionCheckedChange() {
        this.defaultOptionChecked = !this.defaultOptionChecked;
        this.inputChanged();
    }

    inputChanged() {
        var initialValues = this.initialProjectsSelected.map(project => project.id).sort();
        var selectedValues = this.projectsSelected.map(project => project.id);
        if (this.defaultOptionChecked) selectedValues.push(this.defaultProjectSelected.id);
        selectedValues.sort();

        if (initialValues.length === selectedValues.length && initialValues.every((value, index) => value === selectedValues[index])) {
            this.isSubmitDisabled = of(true);
        } else {
            this.isSubmitDisabled = of(false);
        }


    }



}
