import { ApolloError } from "@apollo/client/core";
import { ToastrService } from "ngx-toastr";
import { ContentService } from "./services/content.service";
import { TemplateResponse, ResponseParams } from "../models/notifications/TemplateResponse";
import { CoreErrorsSubscription } from './generated/operations-core-graphql';
import { HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { BeefSubscriptionErrorSubscription } from './generated/operations-protocol-beef-graphql';
import { TranslocoService } from "@jsverse/transloco";
import { CcxConfirmationDialogComponent, ccxConfirmationDialogProps } from "./components/ccx-confirmation-dialog/ccx-confirmation-dialog.component";
import { DialogService } from "./components/dialog/dialog.service";


export function handleApolloTemplateError(err: any, toastr: ToastrService, contSrvc: ContentService) {
    if (err instanceof ApolloError) {
        const httpError = err.networkError as HttpErrorResponse;
        if (httpError?.name === "HttpErrorResponse" && httpError.error?.errors) {
            // TODO: build an error modal with close button instead of toastr for network errors
            const errors: any[] = httpError.error.errors;
            const message = errors.reduce((acc, curr,) => {
                return acc + curr.message + "\n";
            }, '');
            toastr.error(message, 'Error');
            return;
        }
        const response: TemplateResponse = {
            templateId: err.message,
            parameters: err.graphQLErrors && err.graphQLErrors.length > 0 ? err.graphQLErrors[0].extensions['data'] as ResponseParams : null
        }
        const message = contSrvc.translateTemplate(response);
        toastr.warning(message);
    }

    if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        if ('detail' in httpError.error && 'exceptionDetails' in httpError?.error) {
            const details = httpError.error as HttpErrorDetail;
            const message = details.exceptionDetails.reduce((acc, curr) => {
                return acc + curr.message + "\n";
            }, '');
            toastr.error(message, 'Error');
            return;
        } else {
            const errorJson = JSON.stringify(httpError.error);
            toastr.error(errorJson, 'Error');
        }
    }
}

export function handleApolloTemplateErrorOnModal(
    err: any, 
    dialogProps: ccxConfirmationDialogProps, 
    toastr: ToastrService, 
    contSrvc: ContentService,
    dialogService: DialogService
) {
    if (err instanceof ApolloError) {
        const httpError = err.networkError as HttpErrorResponse;
        if (httpError?.name === "HttpErrorResponse" && httpError.error?.errors) {
            const errors: any[] = httpError.error.errors;
            const message = errors.reduce((acc, curr,) => {
                return acc + curr.message + "\n";
            }, '');
            toastr.error(message, 'Error');
            return;
        }
        const response: TemplateResponse = {
            templateId: err.message,
            parameters: err.graphQLErrors && err.graphQLErrors.length > 0 ? err.graphQLErrors[0].extensions['data'] as ResponseParams : null
        }
        dialogProps.subHeaderText = contSrvc.translateTemplate(response);
        dialogService.create(CcxConfirmationDialogComponent, dialogProps);
    }

    if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        if ('detail' in httpError.error && 'exceptionDetails' in httpError?.error) {
            const details = httpError.error as HttpErrorDetail;
            const message = details.exceptionDetails.reduce((acc, curr) => {
                return acc + curr.message + "\n";
            }, '');
            toastr.error(message, 'Error');
            return;
        } else {
            const errorJson = JSON.stringify(httpError.error);
            toastr.error(errorJson, 'Error');
        }
    }
}

export function toastrCoreErrorsSubscription(err: CoreErrorsSubscription, toastr: ToastrService, langSvc: ContentService) {

    const response: TemplateResponse = {
        templateId: err.errors.message,
        parameters: err.errors.exceptions?.[0].data ? err.errors.exceptions[0].data : null
    }
    const message = langSvc.translateTemplate(response);

    toastr.error(message, "Error");
}

export function toastrBeefErrorsSubscription(err: BeefSubscriptionErrorSubscription, toastr: ToastrService, langSvc: ContentService) {
    const response: TemplateResponse = {
        templateId: err.errors.message,
        parameters: err.errors.exceptions[0].data ? err.errors.exceptions[0].data : null
    }
    const message = langSvc.translateTemplate(response);

    toastr.error(message, "Error");
}


export function handleApolloTemplateSuccess(template: TemplateResponse | undefined, toastr: ToastrService, contSrvc: ContentService) {

    if (!template || !template.templateId) {
        console.error("handleApolloTemplateSuccess: no template supplied");
        return;
    }
    const message = contSrvc.translateTemplate(template);

    toastr.success(message);
}


export function localizeGrid(columns: any, source: any[], contSrvc: ContentService, langSrvc: TranslocoService = null) {
    return source.map(p => {
        columns.map((element: { typeValue: string; columnSourceId: string | number; }) => {
            if (element.typeValue === "datetime") {
                if (isFinite(+new Date(p[element.columnSourceId]))) {
                    p[element.columnSourceId] = contSrvc.localized(p[element.columnSourceId]);
                }
            }
            if (element.typeValue === "fulldatetime") {
                if (isFinite(+new Date(p[element.columnSourceId]))) {
                    p[element.columnSourceId] = contSrvc.localized(p[element.columnSourceId], 'medium');
                }
            }
            if (element.columnSourceId === 'status') {
                if (langSrvc === null) console.error(new Error("TranslocoService was not provided on localizeGrid"));
                p[element.columnSourceId] = langSrvc.translate(p[element.columnSourceId]);
            }
        })
        return {
            ...p
        }
    })

}

export function validateGuid(s: string): boolean {
    return s.match('^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$') !== null;
}

export function formatFileSize(sizeInBytes: number): string {
    const units = ["bytes", "KB", "MB", "GB", "TB"];

    if (sizeInBytes == 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return `${(sizeInBytes / Math.pow(1024, e)).toFixed(2)} ${units[e]}`;
}

interface HttpErrorDetail {
    detail: string;
    exceptionDetails: ExceptionDetail[];
}

interface ExceptionDetail {
    message: string;
    raw: string;
    type: string;
}
