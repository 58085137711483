import {Component, OnInit} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {select, Store} from '@ngrx/store';
import {TranslocoService} from "@jsverse/transloco";
import {ContentService} from "src/app/@core/services/content.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {State} from "../../../protocol-beef/state/protocol-beef.reducer";
import { OrganizationDetail } from "src/app/@core/generated/operations-core-graphql";
import { protocolsSelectors } from "../../state";

@Component({
    selector: 'ccx-protocol-organization-profile',
    templateUrl: './protocol-organization-profile.component.html',
    styleUrls: ['./protocol-organization-profile.component.scss'],
})
export class ProtocolOrganizationProfileComponent implements OnInit {
    private onDestroy$ = new Subject<void>();
    isWorking$!: Observable<boolean>;
    isProtocolStateWorking = this.store.selectSignal(protocolsSelectors.selectIsPending);
    protocolID!: string;
    organizationID!: string;
    organization!: OrganizationDetail;

    constructor(readonly store: Store<State>, private route: ActivatedRoute,
        readonly langSvc: TranslocoService,
        readonly contentSrv: ContentService,
        private router: Router,
       readonly toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.store.pipe(takeUntil(this.onDestroy$),
            select(protocolsSelectors.selectCurrentOrganizationDetails)
        ).subscribe(e => {
            this.organization = e;
        });

        this.route.params.subscribe((params) => {
            this.protocolID = params['protocolId'];
            this.organizationID = params['organizationId'];
        });
    }

    openEditOrgFrom() {
        this.router.navigate([`/modules/protocols/${this.protocolID}/organizations/${this.organizationID}/editorganization`]);
    }
}
